export const eea = [
  'at',
  'ax',
  'be',
  'bg',
  'bl',
  'cy',
  'cz',
  'de',
  'dk',
  'ea',
  'ee',
  'es',
  'fi',
  'fr',
  'gb',
  'gf',
  'gg',
  'gi',
  'gp',
  'gr',
  'hr',
  'hu',
  'ic',
  'ie',
  'im',
  'is',
  'it',
  'je',
  'li',
  'lt',
  'lu',
  'lv',
  'mf',
  'mq',
  'mt',
  'nc',
  'nl',
  'no',
  'pf',
  'pl',
  'pm',
  'pt',
  're',
  'ro',
  'se',
  'si',
  'sj',
  'sk',
  'tf',
  'va',
  'wf',
  'yt',
];

export const gbOrUnknown = ['gb', 'gg', 'im', 'je', 'uk', 'unknown'];

export const COUNTRIES_WITH_COOKIE_BANNER = [...eea, ...gbOrUnknown];

export default { eea, gbOrUnknown, COUNTRIES_WITH_COOKIE_BANNER };
